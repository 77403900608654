.container123 {
  display: grid;
  /* grid-gap: 10px; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  max-width: 1300px;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto;
  margin-left: auto;
  margin: 0 auto;
  /* margin-bottom: 15rem; */
}
.grid-item321 {
  grid-column: span 1;
  grid-row: span 1;
  margin-left: 10px;
}